@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* These are dynamically changed by JS with Backend Values (Default values defined here) */
  /* The variable names should match with variables in tailwind.config.js and useSetColorVariables */
  :root {
    /* Colors */
    --color-background: 255, 255, 255;
    --color-text-primary: 0, 0, 0;
    --color-button-primary: 0, 0, 0;
    --color-button-secondary: 0, 0, 0;
    --color-text-button: 255, 255, 255;
    
    --color-dashboard: 255, 255, 255;
    --color-card-primary: 255, 255, 255;
    --color-modal-primary: 255, 255, 255;

    /* Fonts */
    --font-system: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
    --font-primary: var(--font-system);
    --font-secondary: var(--font-system);
    --font-weight-primary: 600;
    --font-tracking-primary: 0.02rem; 
  }
}

@layer components {
  .engagements-button {
    @apply !px-[10px] !py-[4px] sm:!px-4 sm:!py-[6px] !text-sm !rounded-lg mr-3
  }
  .gradient-button {
    @apply bg-gradient-to-br from-primaryButton to-secondaryButton text-textButton
  }
  .gradient-itself {
    @apply bg-gradient-to-br from-primaryButton to-primaryButton/30 text-textButton
  }
  .gradient-button-translucent {
    @apply bg-gradient-to-tl from-primaryButton/50 to-secondaryButton/50 text-textButton
  }
}

@import "quill-override.css";
@import "datetime-picker-override.css";
@import "react-color-override.css";
@import "react-horizontal-scrolling-menu-override.css";
@import "reactyoutubeembed.styles.css";

/* For toasts at bottom center being used for PWA and Push Notif Prompts */
.Toastify__toast-container--bottom-center {
  --toastify-toast-width: 400px;
}

@font-face {
  font-family: "Droid Sans";
  font-style: normal;
  src: url("/fonts/DroidSans.woff") format("woff");
}

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  src: url("/fonts/Helvetica.woff") format("woff");
}

/* Brand Specific Fonts */
@font-face {
  font-family: "Blaster";
  font-style: normal;
  src: url("/fonts/brand-specific/Blaster-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Thyga";
  font-style: normal;
  src: url("/fonts/brand-specific/THYGA-REGULAR.ttf") format("truetype");
}

@font-face {
  font-family: "Biennale";
  font-style: normal;
  src: url("/fonts/brand-specific/Biennale-normal.otf") format("opentype");
}

@font-face {
  font-family: "Rethink Sans";
  font-style: normal;
  src: url("/fonts/brand-specific/Rethink-Sans-normal.ttf") format("truetype");
}

@font-face {
  font-family: "Thyga_Italic";
  font-style: normal;
  src: url("/fonts/brand-specific/THYGA-ITALIC.ttf") format("truetype");
}
@font-face {
  font-family: "KumbhSans";
  font-style: normal;
  src: url("/fonts/brand-specific/doodles/KumbhSans.ttf") format("truetype");
}
@font-face {
  font-family: "Fredoka";
  font-style: normal;
  src: url("/fonts/brand-specific/doodles/Fredoka.ttf") format("truetype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-secondary);
  width: 100%;
  height: 100vh;
  background-color: black;
  /*background-image: linear-gradient(180deg, #101217 11.37%, rgba(16, 18, 23, 0.4555) 42.06%, rgba(16, 18, 23, 0.2) 58.04%, #0A0C11 88.73%), url("/images/background-image.png");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  scroll-behavior: smooth !important;
}

#__next {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.hideScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

::-ms-reveal {
  display: none;
}

/* This will work on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

/* Targets on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  margin: 20px 0;
  background-color: #ffffff20;
}

.whiteScrollbar *::-webkit-scrollbar-track {
  background-color: #ffffff20;
}

.whiteScrollbar *::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

.blackScrollbar *::-webkit-scrollbar-track {
  background-color: #00000020;
}

.blackScrollbar *::-webkit-scrollbar-thumb {
  background-color: #000000;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 0px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #ffffff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*.button {*/
/*    clip-path: polygon(100% 0, 100% 61%, 84% 100%, 0 100%, 0 0);*/
/*}*/

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  clip-path: polygon(100% 0, 100% 63%, 88% 100%, 0 100%, 0 0);
}

.button::after {
  content: "";
  position: absolute;
  height: 65px;
  top: 20px;
  right: -16px;
  transform: rotate(54deg);
  transform-origin: right top;
}

@media only screen and (max-width: 600px) {
  .button {
    clip-path: polygon(100% 0, 100% 66%, 85% 100%, 0 100%, 0 0);
  }

  .button::after {
    content: "";
    position: absolute;
    height: 65px;
    right: -13px;
    transform: rotate(58deg);
    transform-origin: right top;
  }
}

.buttonContainer {
  clip-path: polygon(100% 0, 100% 70%, 88% 100%, 0 100%, 0 0);
}

.transitionEffect {
  animation-name: open;
  animation-duration: 1s;
  height: fit-content;
}

@keyframes open {
  0% {
    max-height: 0px;
    opacity: 0;
  }

  100% {
    max-height: 900px;
    overflow: hidden;
    opacity: 1;
  }
}

.transitionEffectClose {
  animation-name: close;
  animation-duration: 1s;
}

@keyframes close {
  100% {
    max-height: 0px;
    opacity: 0;
    display: none;
  }

  0% {
    max-height: 900px;
    overflow: hidden;
    opacity: 0;
  }
}

.shine {
}

.shine:before {
  content: "";
  position: absolute;
  left: -100px;
  width: 20%;
  height: 100%;
  transform: skewX(-30deg);
  transition: 1.9s;
}

.shine:hover:before {
  left: 500px;
  background: rgba(255, 255, 255, 0.2);
}

@keyframes shine {
  0% {
    left: -40px;
  }
  20% {
    left: -100;
  }
  100% {
    left: 100%;
  }
}

.buttonShine:before {
  content: "";
  position: absolute;
  width: 20%;
  height: 100%;
  transform: skewX(-30deg);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2) 70%
  );
  top: 0;
  left: -100px;
  animation: shine 1.5s infinite linear;
}

input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  border-radius: 4px;
  padding: 1px;
  height: 20px;
  width: 20px;
}

/* TODO */
.primaryButtonGradient {
  position: relative;
  background-image: linear-gradient(var(--color-button-secondary), var(--color-button-primary));
  z-index: 1;
}
.primaryButtonGradient::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: linear-gradient(var(--color-button-secondary), var(--color-button-primary));
  transition: opacity 200ms linear;
  border-radius: 0.625rem;
  opacity: 0;
}
.primaryButtonGradient:hover::before {
  opacity: 1;
}
.grecaptcha-badge { 
    visibility: hidden !important;
}