.react-horizontal-scrolling-menu--arrow-left {
  position: absolute;
  left: 0;
  z-index: 40;
  height: 100%;
}

.react-horizontal-scrolling-menu--arrow-right {
  position: absolute;
  right: 0;
  z-index: 40;
  height: 100%;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  position: relative;
}
