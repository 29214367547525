input[id^="rc-editable-input-"] {
  border-radius: 0.25rem;
  text-align: center;
  background: #454545;
  border: 0;
  box-shadow: none !important;
  outline: 0;
  color: white;
  font-weight: 500;
  font-size: 0.65rem !important;
}

label[for^="rc-editable-input-"] {
  background: black;
  color: white !important;
  margin: 5px 0;
  border-radius: 0.5rem;
  padding: 0 !important;
  font-weight: 600;
}
