@import "react-quill/dist/quill.snow.css";

.quill {
  @apply rounded-xl ring-1 hover:ring-2 ring-primaryText/50 hover:ring-primaryText focus:ring-primaryText !border-0 duration-300 focus:ring-1 focus:outline-none outline-none;
}

.ql-toolbar {
  @apply rounded-t-xl;
  @apply flex;
  @apply justify-end;
  @apply items-center;
}
.ql-container {
  @apply rounded-b-xl;
}
.ql-snow {
  @apply !text-sm;
  @apply !tracking-wide;
  @apply !font-secondary;
}
.ql-blank {
  @apply !text-brandGray/75 !font-normal !not-italic;
}

.ql-toolbar.ql-snow {
  width: 100%;
  @apply border-0;
}
.ql-editor.ql-blank::before {
  font-style: normal !important;
  @apply !text-brandGray/75;
}
.ql-editor {
  min-height: 100px;
}
.ql-container.ql-snow {
  @apply border-0;
  min-height: 100px;
}
/*
.quill blockquote {
    color: #fff;
    border-left: 0.25em solid;
    padding-left: 1rem;
}

.quill a {
    color: #FF3562;
}

.quill h1 {
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 2em;
}

.quill h2 {
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 1.5em;
}

.quill h3 {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 1.17em;
}

.quill h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.quill h5 {
    display: block;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 0.83em;
}

.quill h6 {
    display: block;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 0.67em;
}

.quill .ql-align-right {
    text-align: right;
}

.quill .ql-align-center {
    text-align: center;
}

.quill .ql-align-justify {
    text-align: justify;
}

.quill .ql-size-huge {
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    @apply text-5xl;
}

.quill .ql-size-large {
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    @apply text-3xl;
}

.quill .ql-size-small {
    display: block;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    @apply text-xs;
}

.quill .ql-indent-1 {
    padding-left: 3rem;
}

.quill .ql-indent-2 {
    padding-left: 6rem;
}

.quill .ql-indent-3 {
    padding-left: 9rem;
}

.quill .ql-indent-4 {
    padding-left: 12rem;
}

.quill .ql-indent-5 {
    padding-left: 15rem;
}

.quill .ql-indent-6 {
    padding-left: 18rem;
}

.ql-image {
    display: none !important;
}
.ql-container {
    font-size: 18px !important;
} */
