.dateAndTimePicker {
	line-height: 1.4375em;
	letter-spacing: 0.00938em;
	box-sizing: border-box;
	border: 4px;
	border: solid white;
	border-radius: 9999px;
	align-items: center;
	border-radius: 4px;
	padding-right: 14px;
	color: white;
}

.reactDateTimePicker,
.react-datepicker__header {
	background-color: transparent;
}

.react-datepicker__day {
	border-radius: 20px !important;
	color: #344054 !important;
}

.react-datepicker__day--selected {
	background: #ff3562 !important;
	color: white !important;
}

.react-datepicker__day:hover {
	background: #7c486b !important;
	color: white !important;
}

.react-datepicker__day:active {
	background: #8ae4cf !important;
	color: #fff !important;
}

.react-datepicker__day--keyboard-selected {
	background-color: inherit !important;
}

.react-datepicker__day--disabled {
	color: #a3a5aa !important;
}

.react-datepicker__day--disabled:hover {
	background-color: transparent !important;
	color: #a3a5aa !important;
}
